import { convertNullIfEmpty } from "@/utils/null-check";

export class DeveloperModel {
    static postPayload(e) {
        return {
            name: e.name || ""
        };
    }
    static getToEditResponse(e) {
        return {
            name: e.name || ""
        };
    }
}
