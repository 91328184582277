import { convertNullIfEmpty } from "@/utils/null-check";

export class CityModel {
    static getCityPayload(e) {
        return {
            include: e.include || "",
        };
    }
    static getCityResponse(e) {
        return {
            createdAt: e.createdAt || "",
            id: e.id || "",
            name: e.name || "",
            updatedAt: e.updatedAt || "",
            countryId: e.country != null ? e.country.id : "",
            stateId: e.state != null ? e.state.id : "",
        };
    }
    static postCityPayload(e) {
        return {
            name: convertNullIfEmpty(e.name) || "",
            countryId: convertNullIfEmpty(e.countryId) || "",
            stateId: convertNullIfEmpty(e.stateId) || "",
        };
    }
}