import { convertNullIfEmpty } from "@/utils/null-check";

export class PropertyModel {
    static getPropertyPayload(e) {
        return {
            include: e.include || "",
        };
    }
    static getPropertyResponse(e) {
        return {
            allowPartnership: e.allowPartnership || "",
            area: e.area || "",
            areaUnitType: e.areaUnitType || "",
            building: e.building || "",
            builtUpArea: e.builtUpArea || "",
            builtUpDepth: e.builtUpDepth || "",
            builtUpWidth: e.builtUpWidth || "",
            city: e.city || "",
            commission: e.commission || "",
            country: e.country || "",
            createdAt: e.createdAt || "",
            description: e.description || "",
            furnishingType: e.furnishingType || "",
            gallery: e.gallery || "",
            id: e.id || "",
            landArea: e.landArea || "",
            landDepth: e.landDepth || "",
            landWidth: e.landWidth || "",
            latitude: e.latitude || "",
            longitude: e.longitude || "",
            lotNumber: e.lotNumber || "",
            lotType: e.lotType || "",
            mainPhoto: e.mainPhoto || "",
            numberOfBathroom: e.numberOfBathroom || "",
            numberOfBedroom: e.numberOfBedroom || "",
            offerToPurchase: e.offerToPurchase || "",
            owner: e.owner || "",
            partnershipJobScope: e.partnershipJobScope || "",
            partnershipRemark: e.partnershipRemark || "",
            propertyAmenities: e.propertyAmenities || "",
            propertyCategory: e.propertyCategory || "",
            propertyCustomCategories: e.propertyCustomCategories || "",
            propertyFacilities: e.propertyFacilities || "",
            propertyTags: e.propertyTags || "",
            propertyType: e.propertyType || "",
            purpose: e.purpose || "",
            referenceCode: e.referenceCode || "",
            remark: e.remark || "",
            renovationCost: e.renovationCost || "",
            renovationDetail: e.renovationDetail || "",
            rentPrice: e.rentPrice || "",
            rentPricePerUnit: e.rentPricePerUnit || "",
            salePrice: e.salePrice || "",
            salePricePerUnit: e.salePricePerUnit || "",
            state: e.state || "",
            status: e.status || "",
            street: e.street || "",
            tenureType: e.tenureType || "",
            title: e.title || "",
            updatedAt: e.updatedAt || "",
            videoLink: e.videoLink || "",
        };
    }
    static updatePricePayload(e) {
        return {
            rentPrice: convertNullIfEmpty(e.rentPrice) || "",
            rentPricePerUnit: convertNullIfEmpty(e.rentPricePerUnit) || "",
            salePrice: convertNullIfEmpty(e.salePrice) || "",
            salePricePerUnit: convertNullIfEmpty(e.salePricePerUnit) || "",
        };
    }
    static updateStatusPayload(e) {
        return {
            status: convertNullIfEmpty(e.status) || "",
        };
    }
}