import { convertNullIfEmpty } from "@/utils/null-check";

export class BranchModel {
    static getBranchDetailPayload(e) {
        return {
            include: e.include || ""
        };
    }
    static getBranchDetailForEditResponse(e) {
        return {
            address: e.address || "",
            agencyId: e.agency != null ? e.agency.id : "",
            businessRegisterNumber: e.businessRegisterNumber || "",

            area: e.area || "",
            city: e.city || "",
            state: e.state || "",
            country: e.country || "",
            areaId: e.area != null ? e.area.id : "",
            cityId: e.city != null ? e.city.id : "",
            stateId: e.state != null ? e.state.id : "",
            countryId: e.country != null ? e.country.id : "",

            contact: e.contact || "",
            createdAt: e.createdAt || "",
            description: e.description || "",
            email: e.email || "",
            fax: e.fax || "",
            id: e.id || "",
            latitude: e.latitude || "",
            longitude: e.longitude || "",
            name: e.name || "",
            registerEstateAgentName: e.registerEstateAgentName || "",
            tenancyAgreementPersonInChargeEmail:
                e.tenancyAgreementPersonInChargeEmail || "",

            sstNumber: e.sstNumber || "",
            ssmNumber: e.ssmNumber || "",
            ejenHartanahNumber: e.ejenHartanahNumber || "",
            branchCode: e.branchCode || "",
            updatedAt: e.updatedAt || ""
        };
    }
    static postBranchDetailPayload(e) {
        return {
            address: convertNullIfEmpty(e.address) || "",
            agencyId: convertNullIfEmpty(e.agencyId) || "",
            areaId: convertNullIfEmpty(e.areaId) || "",
            businessRegisterNumber:
                convertNullIfEmpty(e.businessRegisterNumber) || "",
            cityId: convertNullIfEmpty(e.cityId) || "",
            contact: convertNullIfEmpty(e.contact) || "",
            countryId: convertNullIfEmpty(e.countryId) || "",
            description: convertNullIfEmpty(e.description) || "",
            email: convertNullIfEmpty(e.email) || "",
            fax: convertNullIfEmpty(e.fax) || "",
            latitude: convertNullIfEmpty(e.latitude) || "",
            longitude: convertNullIfEmpty(e.longitude) || "",
            name: convertNullIfEmpty(e.name) || "",
            registerEstateAgentName:
                convertNullIfEmpty(e.registerEstateAgentName) || "",
            stateId: convertNullIfEmpty(e.stateId) || "",
            tenancyAgreementPersonInChargeEmail:
                convertNullIfEmpty(e.tenancyAgreementPersonInChargeEmail) || "",

            sstNumber: e.sstNumber || "",
            ssmNumber: e.ssmNumber || "",
            ejenHartanahNumber: e.ejenHartanahNumber || "",
            branchCode: e.branchCode || ""
        };
    }
}
