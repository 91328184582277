import { convertNullIfEmpty } from "@/utils/null-check";

export class AreaModel {
    static getAreaPayload(e) {
        return {
            include: e.include || ""
        };
    }
    static getAreaForResponse(e) {
        return {
            createdAt: e.createdAt || "",
            id: e.id || "",
            name: e.name || "",
            updatedAt: e.updatedAt || "",

            countryId: e.country != null ? e.country.id : "",
            stateId: e.state != null ? e.state.id : "",
            cityId: e.city != null ? e.city.id : "",
            country: e.country || "",
            state: e.state || "",
            city: e.city || "",

            officialName: e.officialName || "",
            postalCode: e.postalCode || ""
        };
    }
    static postAreaPayload(e) {
        return {
            name: convertNullIfEmpty(e.name) || "",
            countryId: convertNullIfEmpty(e.countryId) || "",
            stateId: convertNullIfEmpty(e.stateId) || "",
            cityId: convertNullIfEmpty(e.cityId) || "",
            officialName: convertNullIfEmpty(e.officialName) || "",
            postalCode: convertNullIfEmpty(e.postalCode) || ""
        };
    }
}
