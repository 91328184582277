import { convertNullIfEmpty } from "@/utils/null-check";

export class PropertyTypeModel {
    static getPropertyTypeDetailPayload(e) {
        return {
            include: e.include || "",
        };
    }
    static getPropertyTypeDetailResponse(e) {
        return {
            code: e.code || "",
            createdAt: e.createdAt || "",
            description: e.description || "",
            downPaymentRate: e.downPaymentRate || "",
            id: e.id || "",
            interestRate: e.interestRate || "",
            name: e.name || "",
            propertyCategory: e.propertyCategory || "",
            term: e.term || "",
            updatedAt: e.updatedAt || "",
        };
    }
    static postPropertyTypeDetailPayload(e) {
        return {
            code: convertNullIfEmpty(e.code) || "",
            description: convertNullIfEmpty(e.description) || "",
            downPaymentRate: convertNullIfEmpty(e.downPaymentRate) || "",
            interestRate: convertNullIfEmpty(e.interestRate) || "",
            name: convertNullIfEmpty(e.name) || "",
            propertyCategory: convertNullIfEmpty(e.propertyCategory) || "",
            term: convertNullIfEmpty(e.term) || "",
        };
    }
}