export class LoanApplicationDocFormModel {
    static getCategoryToEdit(e) {
        return {
            id: e.id,
            category: e.name || "",
            fields: e.loanApplicationDocuments || []
        };
    }
    static postCategoryPayload(e) {
        return {
            loanApplicationDocumentCategoryName: e.category || "",
            loanApplicationDocuments: e.fields || []
        };
    }
    static putCategoryPayload(e) {
        return {
            loanApplicationDocumentCategoryName: e.category || "",
            loanApplicationDocuments: e.fields || []
        };
    }

    static getAdditionalToEdit(e) {
        return {
            fields: e || []
        };
    }
    static putAdditionalDocPayload(e) {
        return {
            loanApplicationAdditionalDocuments: e.fields || []
        };
    }
}
