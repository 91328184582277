import { convertNullIfEmpty } from "@/utils/null-check";

export class BuildingModel {
    static getBuildingPayload(e) {
        return {
            include: e.include || ""
        };
    }
    static getBuildingResponse(e) {
        return {
            createdAt: e.createdAt || "",
            id: e.id || "",
            name: e.name || "",
            updatedAt: e.updatedAt || "",

            country: e.country || "",
            state: e.state || "",
            city: e.city || "",
            area: e.area || "",
            countryId: e.country != null ? e.country.id : "",
            stateId: e.state != null ? e.state.id : "",
            cityId: e.city != null ? e.city.id : "",
            areaId: e.area != null ? e.area.id : "",

            officialName: e.officialName || "",
            parentBuildingId:
                e.parentBuilding != null ? e.parentBuilding.id : "",
            street: e.street || ""
        };
    }
    static postBuildingPayload(e) {
        return {
            name: convertNullIfEmpty(e.name) || "",
            countryId: convertNullIfEmpty(e.countryId) || "",
            stateId: convertNullIfEmpty(e.stateId) || "",
            cityId: convertNullIfEmpty(e.cityId) || "",
            areaId: convertNullIfEmpty(e.areaId) || "",
            officialName: convertNullIfEmpty(e.officialName) || "",
            parentBuildingId: convertNullIfEmpty(e.parentBuildingId) || "",
            street: convertNullIfEmpty(e.street) || ""
        };
    }
}
