import { convertNullIfEmpty } from "@/utils/null-check";

export class CustomCategoryModel {
    static getCustomCategoryDetailPayload(e) {
        return {
            include: e.include || "",
        };
    }
    static getCustomCategoryDetailResponse(e) {
        return {
            createdAt: e.createdAt || "",
            description: e.description || "",
            id: e.id || "",
            name: e.name || "",
            updatedAt: e.updatedAt || "",
        };
    }
    static postCustomCategoryDetailPayload(e) {
        return {
            name: convertNullIfEmpty(e.name) || "",
            description: convertNullIfEmpty(e.description) || "",
        };
    }
}