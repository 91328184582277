import { convertNullIfEmpty } from "@/utils/null-check";
import { appBannerType } from "@/enums";

export class AppBannerModel {
    static getToEdit(e) {
        return {
            title: e.title || "",
            image: e.image || [],
            type: e.type || "",
            payload: e.payload || {}
        };
    }
    static postPayload(e) {
        let model = {
            title: e.title || "",
            image: e.image || [],
            type: e.type || ""
        };

        if (e.type === appBannerType.WEBVIEW) {
            model.payload = convertNullIfEmpty({ url: e.payload.url }) || {};
        }
        if (e.type === appBannerType.PROJECT) {
            model.payload =
                convertNullIfEmpty({ projectId: e.payload.project.id }) || {};
        }
        if (e.type === appBannerType.NO_ACTION) {
            model.payload = {};
        }

        return model;
    }
}
