import { convertNullIfEmpty } from "@/utils/null-check";

export class CountryModel {
    static getCountryPayload(e) {
        return {
            include: e.include || "",
        };
    }
    static getCountryResponse(e) {
        return {
            createdAt: e.createdAt || "",
            id: e.id || "",
            name: e.name || "",
            updatedAt: e.updatedAt || "",
        };
    }
    static postCountryPayload(e) {
        return {
            name: convertNullIfEmpty(e.name) || "",
        };
    }
}