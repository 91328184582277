import { convertNullIfEmpty } from "@/utils/null-check";

export class AgencyUserModel {
    static getAgencyUserPayload(e) {
        return {
            include: e.include || ""
        };
    }
    static getAgencyUserForEditResponse(e) {
        return {
            actualName: e.actualName || "",
            address: e.address || "",
            agencyId: e.agency != null ? e.agency.id : "",
            branchId: e.agencyBranch != null ? e.agencyBranch.id : "",
            avatar: e.avatar || [],
            bank: e.bank || "",
            bankAccountFullName: e.bankAccountFullName || "",
            bankAccountNumber: e.bankAccountNumber || "",
            birthdate: e.user?.birthdate || "",

            country: e.country || "",
            state: e.state || "",
            city: e.city || "",
            area: e.area || "",
            countryId: e.country != null ? e.country.id : "",
            stateId: e.state != null ? e.state.id : "",
            cityId: e.city != null ? e.city.id : "",
            areaId: e.area != null ? e.area.id : "",

            introduction: e.introduction || "",
            email: e.user?.email || "",
            experience: e.experience || "",
            renLicense: e.renLicense || "",
            gender: e.user?.gender || "",

            mobileISOCode: e.user.mobileISOCode || "",
            mobileCountryCode: e.user.mobileCountryCode || "",
            mobileNumber: e.user.mobileNumber || "",
            id: e.id || "",
            isActive: e.isActive || "",
            isCertified: e.isCertified || "",
            name: e.name || "",
            roleId: e.rolePermission != null ? e.rolePermission.id : ""
        };
    }
    static postAgencyUserPayload(e) {
        return {
            actualName: convertNullIfEmpty(e.actualName) || "",
            address: convertNullIfEmpty(e.address) || "",
            agencyId: convertNullIfEmpty(e.agencyId) || "",
            areaId: convertNullIfEmpty(e.areaId) || "",
            avatar: convertNullIfEmpty(e.avatar) || [],
            bank: convertNullIfEmpty(e.bank) || "",
            bankAccountFullName:
                convertNullIfEmpty(e.bankAccountFullName) || "",
            bankAccountNumber: convertNullIfEmpty(e.bankAccountNumber) || "",
            birthdate: convertNullIfEmpty(e.birthdate) || "",
            branchId: convertNullIfEmpty(e.branchId) || "",
            cityId: convertNullIfEmpty(e.cityId) || "",

            mobileISOCode: convertNullIfEmpty(e.phoneNo.mobileISOCode) || "",
            mobileCountryCode:
                convertNullIfEmpty(e.phoneNo.mobileCountryCode) || "",
            mobileNumber: convertNullIfEmpty(e.phoneNo.mobileNumber) || "",

            countryId: convertNullIfEmpty(e.countryId) || "",
            email: convertNullIfEmpty(e.email) || "",
            experience: convertNullIfEmpty(e.experience) || "",
            renLicense: convertNullIfEmpty(e.renLicense) || "",
            gender: convertNullIfEmpty(e.gender) || "",
            introduction: convertNullIfEmpty(e.introduction) || "",
            name: convertNullIfEmpty(e.name) || "",
            roleId: convertNullIfEmpty(e.roleId) || "",
            stateId: convertNullIfEmpty(e.stateId) || "",
            password: convertNullIfEmpty(e.password) || ""
        };
    }
}
