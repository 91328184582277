import { convertNullIfEmpty } from "@/utils/null-check";

export class ProjectMarketingStaffModel {
    static getMarketingStaffResponse(e) {
        return {
            email: e.user?.email || "",
            password: e.password || "",
            name: e.name || "",
            developerId: e.developer ? e.developer.id : "",
            roleId: e.rolePermission?.id || "",

            mobileISOCode: e.user.mobileISOCode || "",
            mobileCountryCode: e.user.mobileCountryCode || "",
            mobileNumber: e.user.mobileNumber || ""
        };
    }
    static postMarketingStaffPayload(e) {
        return {
            email: e.email || "",
            password: e.password || "",
            name: e.name || "",
            developerId: e.developerId.toString() || "",
            roleId: e.roleId.toString() || "",

            mobileISOCode: convertNullIfEmpty(e.phoneNo.mobileISOCode) || "",
            mobileCountryCode:
                convertNullIfEmpty(e.phoneNo.mobileCountryCode) || "",
            mobileNumber: convertNullIfEmpty(e.phoneNo.mobileNumber) || ""
        };
    }
    static putMarketingStaffPayload(e) {
        return {
            email: e.email || "",
            name: e.name || "",
            developerId: e.developerId.toString() || "",
            roleId: e.roleId.toString() || "",

            mobileISOCode: convertNullIfEmpty(e.phoneNo.mobileISOCode) || "",
            mobileCountryCode:
                convertNullIfEmpty(e.phoneNo.mobileCountryCode) || "",
            mobileNumber: convertNullIfEmpty(e.phoneNo.mobileNumber) || ""
        };
    }
}
