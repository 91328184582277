import { convertNullIfEmpty } from "@/utils/null-check";

export class StateModel {
    static getStatePayload(e) {
        return {
            include: e.include || "",
        };
    }
    static getStateForEditResponse(e) {
        return {
            createdAt: e.createdAt || "",
            id: e.id || "",
            name: e.name || "",
            countryId: e.country != null ? e.country.id : "",
            updatedAt: e.updatedAt || "",
        };
    }
    static postStatePayload(e) {
        return {
            name: convertNullIfEmpty(e.name) || "",
            countryId: convertNullIfEmpty(e.countryId) || "",
        };
    }
}