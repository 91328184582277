import { convertNullIfEmpty } from "@/utils/null-check";

export class AmenityModel {
    static getAmenityResponse(e) {
        return {
            createdAt: e.createdAt || "",
            description: e.description || "",
            id: e.id || "",
            name: e.name || "",
            updatedAt: e.updatedAt || "",
        };
    }
    static postAmenityPayload(e) {
        return {
            name: convertNullIfEmpty(e.name) || "",
            description: convertNullIfEmpty(e.description) || "",
        };
    }
}