import { convertNullIfEmpty } from "@/utils/null-check";

export class LawyerModel {
    static getLawyerResponse(e) {
        return {
            email: e.user?.email || "",
            password: e.password || "",
            name: e.name || "",
            lawyerFirmName: e.lawyerFirmName || "",

            mobileISOCode: e.user.mobileISOCode || "",
            mobileCountryCode: e.user.mobileCountryCode || "",
            mobileNumber: e.user.mobileNumber || ""
        };
    }
    static postLawyerPayload(e) {
        return {
            email: e.email || "",
            password: e.password || "",
            name: e.name || "",
            lawyerFirmName: e.lawyerFirmName || "",

            mobileISOCode: convertNullIfEmpty(e.phoneNo.mobileISOCode) || "",
            mobileCountryCode:
                convertNullIfEmpty(e.phoneNo.mobileCountryCode) || "",
            mobileNumber: convertNullIfEmpty(e.phoneNo.mobileNumber) || ""
        };
    }
    static putLawyerPayload(e) {
        return {
            email: e.email || "",
            name: e.name || "",
            lawyerFirmName: e.lawyerFirmName || "",

            mobileISOCode: convertNullIfEmpty(e.phoneNo.mobileISOCode) || "",
            mobileCountryCode:
                convertNullIfEmpty(e.phoneNo.mobileCountryCode) || "",
            mobileNumber: convertNullIfEmpty(e.phoneNo.mobileNumber) || ""
        };
    }
}
