import { convertNullIfEmpty } from "@/utils/null-check";

export class AgencyRoleModel {
    static postAgencyRolePayload(e) {
        return {
            agencyId: convertNullIfEmpty(e.agencyId) || "",
            description: convertNullIfEmpty(e.description) || "",
            isAgencyAdmin: e.isAgencyAdmin || false,
            isBranchAdmin: e.isBranchAdmin || false,
            name: convertNullIfEmpty(e.name) || "",
            permission: convertNullIfEmpty(e.permission) || []
        };
    }
}
